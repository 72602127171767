import React from 'react'
import {useResizeObserver} from '@kensho/tacklebox'

interface Size {
  height: number
  width: number
}

interface PopoverDynamicContentProps {
  onResize: (size: Size) => void
  children: React.ReactNode
}

export default function PopoverDynamicContent({
  onResize,
  children,
}: PopoverDynamicContentProps): JSX.Element {
  const ref = useResizeObserver(onResize)
  return <div ref={ref}>{children}</div>
}
