import {useLocation} from '@reach/router'
import React from 'react'
import {Helmet} from 'react-helmet'

import siteConfig from '../docs/siteconfig.yaml'
import {PageEntry, SiteConfig} from '../types'
import getIndexRelativeUrl from '../utils/getIndexRelativeUrl'

const {siteMetadata} = siteConfig as SiteConfig

interface MetaProps {
  title?: string
  description?: string
  pageConfig?: Partial<PageEntry>
}

const DEFAULT_TITLE = 'Kensho Documentation'

function getTitle(title?: string, pageConfig?: Partial<PageEntry>): string {
  if (title) return title
  if (pageConfig?.title)
    return siteMetadata?.title ? `${pageConfig.title} - ${siteMetadata.title}` : pageConfig.title
  if (siteMetadata?.title) return siteMetadata.title
  return DEFAULT_TITLE
}

function getDescription(
  description?: string,
  pageConfig?: Partial<PageEntry>,
  siteTitle?: string
): string {
  return (
    description ||
    pageConfig?.description ||
    `Developer documentation${siteTitle ? ` for ${siteTitle}` : ''}`
  )
}

export default function Meta({title, description, pageConfig}: MetaProps): JSX.Element {
  const location = useLocation()
  const pageTitle = getTitle(title, pageConfig)
  const pageDescription = getDescription(description, pageConfig, siteMetadata?.title)

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      {/* Social tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={pageTitle} />
      <meta
        property="og:image"
        content={`${location.origin}${getIndexRelativeUrl('/kensho-social-card.png')}`}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={pageTitle} />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={pageTitle} />
    </Helmet>
  )
}
