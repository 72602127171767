import React, {useEffect, useState} from 'react'
import {useLocation, navigate} from '@reach/router'
import queryString from 'query-string'
import {css} from '@emotion/react'

import Modal from './Modal'

const modalCss = css`
  width: 450px;
  height: 140px;
`

const contentCss = css`
  overflow: auto;
  height: 90px;
  text-align: center;
  padding: 12px;

  p {
    margin: 8px;
  }
`

export default function ErrorModal(): JSX.Element | null {
  const location = useLocation()
  const {error, errorDescription, ...otherQueryParams} = queryString.parse(location.search)
  const [{displayError, displayDescription}, setError] = useState<{
    displayError?: string
    displayDescription?: string
  }>({
    displayError: error as string,
    displayDescription: errorDescription as string,
  })

  useEffect(() => {
    if (error) {
      const dismissedQueryString = queryString.stringify(otherQueryParams)
      navigate(`./${dismissedQueryString ? `?${dismissedQueryString}` : ''}${location.hash}`, {
        replace: true,
      })
    }
  }, [otherQueryParams, location.hash, error])

  return (
    <Modal
      modalCssOverride={modalCss}
      isOpen={!!displayError}
      onClose={() => setError({})}
      title={displayError ? (displayError as string) : 'An error has occurred'}
    >
      <section css={contentCss}>
        <p>{displayDescription || displayError}</p>
      </section>
    </Modal>
  )
}
