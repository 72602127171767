import React, {useContext, useState, useCallback} from 'react'
import {css} from '@emotion/react'

import useClickOutside from '../hooks/useClickOutside'
import normalizePath from '../utils/normalizePath'
import siteConfig from '../docs/siteconfig.yaml'
import {SiteConfig} from '../types'
import UserContext from '../providers/UserContext'
import {isSSR} from '../constants'

import Link from './Link'
import Popover from './Popover'
import SignInModal from './SignInModal'

const {endpoints = {}, sitePages = []} = siteConfig as SiteConfig
const accountInfoPage = sitePages.find((page) => page.type === 'account')
const accountPages = sitePages.filter(
  (page) => page.section === 'account' && page.type !== 'account'
)

const userInfoCss = css`
  color: white;
  position: relative;
  z-index: 2;
  border-left: 1px solid #2d2d2d;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 6px;
`

const buttonCss = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  background: none;
  border: none;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  outline: none;

  &:hover {
    background: rgb(95, 101, 106);
  }
`

const listCss = css`
  list-style-type: none;
  min-width: 130px;
  margin: 0;
`

const listItemCss = css`
  display: flex;
`

const menuButtonCss = css`
  flex: 1 1 0%;
  color: black;
  text-decoration: none;
  padding: 0 8px;
  background: white;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;

  &:hover {
    background: #e4e4ed;
  }
`

export default function UserInfo(): JSX.Element | null {
  const {user, loginUrl, logout, _state: userState} = useContext(UserContext)

  const [signInOpen, setSignInOpen] = useState(false)
  const openSignIn = useCallback(() => setSignInOpen(true), [])
  const closeSignIn = useCallback(() => setSignInOpen(false), [])

  const [menuOpen, setMenuOpen] = useState(false)
  const openMenu = useCallback(() => setMenuOpen(true), [])
  const closeMenu = useCallback(() => setMenuOpen(false), [])
  const clickOutsideRef = useClickOutside(closeMenu)

  if (isSSR) return null

  // wait for user state to be known before rendering anything
  // authentication is disabled if endpoints.user and endpoints.login aren't both present
  if (!loginUrl || !endpoints?.user || userState === 'unknown') return null

  if (!user?.email) {
    return (
      <div css={userInfoCss}>
        <button
          data-testid="user-sign-in"
          ref={clickOutsideRef}
          type="button"
          css={buttonCss}
          onClick={openSignIn}
        >
          Sign in
        </button>
        {signInOpen && <SignInModal onClose={closeSignIn} />}
      </div>
    )
  }

  return (
    <Popover
      isOpen={menuOpen}
      target={
        <div data-testid="user-info" css={userInfoCss}>
          <button ref={clickOutsideRef} type="button" css={buttonCss} onClick={openMenu}>
            {user.email}
          </button>
        </div>
      }
    >
      <ul data-testid="user-actions" css={listCss}>
        {accountPages.map((page) => (
          <li css={listItemCss} key={page.title}>
            <Link css={menuButtonCss} to={normalizePath(page.url)}>
              {page.title}
            </Link>
          </li>
        ))}
        <li css={listItemCss}>
          <Link css={menuButtonCss} to={normalizePath(accountInfoPage?.url || '/account')}>
            {accountInfoPage?.title || 'Account'}
          </Link>
        </li>
        {endpoints?.logout && (
          <li css={listItemCss}>
            <button css={menuButtonCss} type="button" onClick={logout}>
              Log out
            </button>
          </li>
        )}
      </ul>
    </Popover>
  )
}
