import React, {forwardRef} from 'react'
import {Link as GatsbyLink, GatsbyLinkProps} from 'gatsby'
import {css} from '@emotion/react'

import normalizePath from '../utils/normalizePath'

export interface LinkProps extends GatsbyLinkProps<Record<string, unknown>> {
  external?: boolean
}

// these paths cannot be handled with client-side routing
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const BLOB_REGEX = /^blob:/
const EXTERNAL_LINK_REGEX = /^https?:\/\//
const EXTERNAL_REGEX = /^\/\.\./

const linkCss = css`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  padding: 0;

  svg {
    vertical-align: middle;
  }
`

function Link(
  {to, children, external, ...passthru}: LinkProps,
  ref: React.Ref<HTMLAnchorElement>
): JSX.Element {
  // email
  if (EMAIL_REGEX.test(to)) {
    return (
      <a ref={ref} href={`mailTo:${to}`}>
        {children || to}
      </a>
    )
  }

  // blob
  if (BLOB_REGEX.test(to)) {
    return (
      <a ref={ref} href={to} target="_blank" rel="noopener noreferrer" {...passthru}>
        {children}
      </a>
    )
  }

  const isAbsoluteUrl = EXTERNAL_LINK_REGEX.test(to)
  const isExternalPath = EXTERNAL_REGEX.test(to)

  // server-side or external
  if (isAbsoluteUrl || isExternalPath || external) {
    /* eslint-disable react/jsx-no-target-blank */
    return (
      <a
        ref={ref}
        href={to}
        target={isAbsoluteUrl ? '_blank' : '_self'}
        rel={isAbsoluteUrl ? 'noopener noreferrer' : ''}
        {...passthru}
      >
        {children}
      </a>
    )
    /* eslint-enable react/jsx-no-target-blank */
  }

  // purely client-side route
  return (
    /* @ts-ignore */
    <GatsbyLink ref={ref} to={normalizePath(to)} {...passthru} css={linkCss}>
      {children}
    </GatsbyLink>
  )
}

export default forwardRef(Link)
