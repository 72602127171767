import React, {useContext, useMemo} from 'react'
import {css} from '@emotion/react'
import {IconChevronRight} from '@kensho/icons'
import {graphql, PageProps, useStaticQuery} from 'gatsby'

import siteConfig from '../docs/siteconfig.yaml'
import {PageEntry, SiteConfig} from '../types'
import UserContext from '../providers/UserContext'
import {headerHeight} from '../constants'

import Link from './Link'
import UserInfo from './UserInfo'
import VersionSelector from './VersionSelector'
import KenshoLogo from './KenshoLogo'
import HorizontalNav from './nav/HorizontalNav'

const {sitePages = [], envVars = {}} = siteConfig as SiteConfig

interface HeaderProps {
  title?: string
  pageConfig?: Partial<PageEntry>
  location: PageProps['location']
}

const headerCss = css`
  background: #353b3e;
  border-bottom: 1px solid black;
  height: ${headerHeight}px;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 14px;
  flex: 0 0 auto;
`

const pathSeparatorCss = css`
  color: white;
  margin: 0 5px;
`

const pathCss = css`
  margin: 0;
  color: rgba(255, 255, 255, 0.87);
  outline: none;
  text-decoration: none;
  font-size: 14px;
`

const logoLinkCss = css`
  display: flex;
  align-items: center;
`

const developersLogoCss = css`
  height: 24px;
  margin: 0;
`

const rightContentCss = css`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: auto;
`

const breadcrumbsCss = css`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export default function Header({title: _title, pageConfig, location}: HeaderProps): JSX.Element {
  const {title} = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)?.site?.siteMetadata

  const {user} = useContext(UserContext)
  const siteNavList = useMemo(
    () =>
      sitePages.filter((page) => {
        // account link will be placed under UserInfo popover
        if (page.type === 'account' || page.section === 'account') return false
        // hide links with authDisplay mode based on auth state
        if (page.authDisplay === 'unauthenticated' && user?.email) return false
        if (page.authDisplay === 'authenticated' && !user?.email) return false
        return true
      }),
    [user]
  )

  const siteId = siteConfig?.id

  return (
    <header css={headerCss}>
      <Link
        css={logoLinkCss}
        to={
          siteId ? `https://services.kensho.com?product=${siteId}` : 'https://services.kensho.com'
        }
      >
        <KenshoLogo css={developersLogoCss} />
      </Link>
      <div css={breadcrumbsCss}>
        <IconChevronRight css={pathSeparatorCss} size={30} />
        <Link css={pathCss} to={envVars.projectHome || '/'}>
          {_title || title}
        </Link>
        {!!pageConfig?.title && (
          <>
            <IconChevronRight css={pathSeparatorCss} size={30} />
            <p data-testid="page-title" css={pathCss}>
              {pageConfig.title}
            </p>
          </>
        )}
      </div>
      <VersionSelector />
      <div data-testid="site-nav" css={rightContentCss}>
        <HorizontalNav
          dense
          location={location}
          navList={siteNavList}
          activeItem={pageConfig?.url}
        />
        <UserInfo />
      </div>
    </header>
  )
}
