import {css, SerializedStyles} from '@emotion/react'
import {Dialog, Overlay} from '@kensho/ui'
import React from 'react'

const modalContainerCss = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(5px);
  background: rgba(30, 35, 37, 0.5);
  z-index: 1;
`

const modalCss = css`
  background: white;
  border-radius: 3px;
  margin: 200px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  > div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(228, 228, 237);
    border-bottom: 1px solid rgb(205, 205, 213);
    border-radius: 3px 3px 0 0;

    > h4 {
      font-size: 18px;
      font-weight: bold;
      flex: 0 1 auto;
      margin: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      border: none;
      background: none;
      margin: auto 16px;
      padding: 0;
      color: #828287;
      outline: none;
    }
  }
`

interface ModalProps {
  modalCssOverride?: SerializedStyles
  children: React.ReactNode
  isOpen?: boolean
  title?: string
  onClose?: () => void
}

export default function Modal({
  modalCssOverride,
  children,
  title,
  isOpen,
  onClose,
}: ModalProps): JSX.Element | null {
  return (
    <Overlay isOpen={isOpen} css={modalContainerCss}>
      <Dialog isOpen={isOpen} title={title} onClose={onClose} css={[modalCssOverride, modalCss]}>
        {children}
      </Dialog>
    </Overlay>
  )
}
