// TODO: replace with @kensho/tacklebox#useClickOutside once available
import * as React from 'react'

interface ClickOutsideOptions {
  /** The type of event to which to subscribe. */
  eventType?: 'click' | 'mousedown' | 'mouseup'
}

/**
 * Invokes a callback when a click event occurs outside of the bounds of an element.
 *
 * @param callback The callback to invoke.
 * @param options Additional configuration options.
 * @returns A ref to attach to the desired element.
 */
export default function useClickOutside(
  callback: (event: MouseEvent) => void,
  options: ClickOutsideOptions = {}
): (node: HTMLElement | null) => void {
  const {eventType = 'click'} = options
  const elementRef = React.useRef<HTMLElement | null>(null)
  const callbackRef = React.useRef(callback)

  React.useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  React.useEffect(() => {
    function handleEvent(event: MouseEvent): void {
      const element = elementRef.current
      if (!element) return
      if (!(event.target instanceof Node)) return
      if (!element.contains(event.target)) callbackRef.current(event)
    }

    window.addEventListener(eventType, handleEvent)
    return () => window.removeEventListener(eventType, handleEvent)
  }, [eventType])

  return React.useCallback((node) => {
    elementRef.current = node
  }, [])
}
