import {css} from '@emotion/react'
import Cookies from 'js-cookie'
import {useCallback, useState} from 'react'

import siteConfig from '../docs/siteconfig.yaml'
import useIsMounted from '../hooks/useIsMounted'
import {SiteConfig} from '../types'

import Link from './Link'

const {envVars} = siteConfig as SiteConfig

function useCookieFlag(name: string): [boolean | undefined, (value: boolean) => void] {
  const [isEnabled, setIsEnabled] = useState(() => {
    const cookie = Cookies.get(name)
    return cookie === undefined ? undefined : cookie === 'true'
  })

  const setCookieFlag = useCallback(
    (value: boolean): void => {
      Cookies.set(name, `${value}`, {expires: 365})
      setIsEnabled(value)
    },
    [name]
  )

  return [isEnabled, setCookieFlag]
}

const bannerCss = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 30px;
`

const gdprConsentCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`

const textCss = css`
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 21px;

  @media (max-width: 720px) {
    margin: 0;
  }
`

const buttonCss = css`
  outline: none;
  appearance: none;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 16px;
  padding: 10px 50px;
  white-space: nowrap;
  transition: all 0.1s ease;

  &:hover {
    opacity: 0.75;
  }
`

const outlinedButtonCss = css`
  border: 1px solid #fff;

  @media (max-width: 720px) {
    width: 100%;
    margin-top: 1rem;
  }
`

const textButtonCss = css`
  border: none;
  text-decoration: underline;
`

const linkCss = css`
  color: white;
  text-decoration: underline;
`

export default function CookieConsentPopup(): JSX.Element | null {
  const [isEnabled, setIsEnabled] = useCookieFlag('gdprConsentCookie')
  const isMounted = useIsMounted()

  // avoid flash of banner when cookie is set
  if (!isMounted) return null

  // skip if they've already made a choice, or if GA4 is not configured
  if (!envVars?.ga4ID || isEnabled !== undefined) return null

  return (
    <div css={bannerCss}>
      <div css={gdprConsentCss}>
        <p css={textCss}>
          Kensho uses cookies to collect information about how you use the site to help us with site
          analysis and performance. By clicking “OK”, you agree to our use of cookies. Learn about
          our cookies and how to modify your preferences in our{' '}
          <Link css={linkCss} to="https://www.spglobal.com/en/cookie-notice">
            cookie notice
          </Link>
        </p>
        <button
          css={[buttonCss, outlinedButtonCss]}
          type="button"
          onClick={() => {
            setIsEnabled(true)
            /* @ts-expect-error: ts(7015) */
            window[`ga-disable-${envVars.ga4ID}`] = false
            window.gtag('js', new Date())
            window.gtag('config', `${envVars.ga4ID}`, {anonymize_ip: true})
          }}
        >
          OK
        </button>
        <button
          css={[buttonCss, textButtonCss]}
          type="button"
          onClick={() => {
            setIsEnabled(false)
            /* @ts-expect-error: ts(7015) */
            window[`ga-disable-${envVars.ga4ID}`] = true
          }}
        >
          Opt out
        </button>
      </div>
    </div>
  )
}
