import * as React from 'react'
import {css} from '@emotion/react'

import {KENSHO_PUBLIC} from '../constants'
import siteConfig from '../docs/siteconfig.yaml'
import {SiteConfig} from '../types'

import Link from './Link'

const {siteMetadata = {}, sitePages = []} = siteConfig as SiteConfig

const footerCss = css`
  display: flex;
  width: 100%;
  max-width: 1000px;
  justify-content: space-between;
  height: 50px;
  margin: 10px auto;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: auto;
    margin: 10px 12px;
  }

  @media screen and (max-width: 600px) {
    display: block;
  }
`

const sectionCss = css`
  flex: 0 1 auto;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`

const subHeaderCss = css`
  margin-bottom: 0;
`

export default function Footer(): JSX.Element {
  const contactUrl =
    sitePages.find((page) => page.type === 'contact' || page.title.toLowerCase() === 'contact')
      ?.url || KENSHO_PUBLIC.CONTACT

  return (
    <footer css={footerCss}>
      <div css={sectionCss}>
        <p css={subHeaderCss}>
          Have questions?{' '}
          <Link to={contactUrl}>
            {`Contact ${siteMetadata.title ? `the ${siteMetadata.title} Team` : 'Us'}`}
          </Link>
        </p>
      </div>
      <div css={sectionCss}>
        <Link to={KENSHO_PUBLIC.TERMS_OF_USE}>Web Terms</Link>
        {'  '}|{'  '}
        <Link to={KENSHO_PUBLIC.TERMS_OF_SERVICE}>Service Terms</Link>
        {'  '}|{'  '}
        <Link to={KENSHO_PUBLIC.PRIVACY_POLICY}>Privacy</Link>
      </div>
    </footer>
  )
}
