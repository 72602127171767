import {css} from '@emotion/react'
import React from 'react'
import {PageProps} from 'gatsby'

import Header from '../components/Header'
import Meta from '../components/Meta'
import {PageEntry} from '../types'
import {headerHeight} from '../constants'

import Base from './Base'

const pageContainerCss = css`
  margin-top: ${headerHeight}px;
  flex: 1 0 auto;
`

const mainCss = css``

export const mainContentCss = css`
  flex: 1 1 auto;
  overflow: hidden;
`

export const fixedHeaderCss = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

interface PageWithHeaderProps {
  className?: string
  children: React.ReactNode
  title?: string
  pageConfig: Partial<PageEntry>
  location: PageProps['location']
}

export default function PageWithHeader({
  className,
  children,
  title,
  pageConfig,
  location,
}: PageWithHeaderProps): JSX.Element {
  return (
    <>
      <Meta title={title} pageConfig={pageConfig} />
      <Base>
        <div className={className} css={pageContainerCss}>
          <header css={fixedHeaderCss}>
            <Header location={location} title={title} pageConfig={pageConfig} />
          </header>
          <main css={[mainCss, mainContentCss]}>{children}</main>
        </div>
      </Base>
    </>
  )
}
