import React from 'react'
import {css} from '@emotion/react'

import getLoginUrl from '../utils/getLoginUrl'
import {MARKETPLACE_IDP} from '../constants'
import useIsMounted from '../hooks/useIsMounted'

import Modal from './Modal'

interface SignInModalProps {
  isOpen?: boolean
  onClose?: () => void
}

const modalCss = css`
  width: 300px;
  height: 220px;
`

const buttonLinkCss = css`
  display: inline-block;
  background: #efefef;
  color: black;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  width: 100%;
  transition: all 0.3s;

  &:hover {
    opacity: 0.7;
  }
`

const primaryCss = css`
  background: #1a819a;
  color: white;
`

const contentCss = css`
  text-align: center;
  padding: 20px;
`

const dividerCss = css`
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #999;
  margin: 10px 0;

  &::before,
  &::after {
    content: '';
    flex: 1 1 auto;
    height: 1px;
    background: #eee;
  }

  &::before {
    margin-right: 5px;
  }

  &::after {
    margin-left: 5px;
  }
`

export default function SignInModal({isOpen = true, onClose}: SignInModalProps): JSX.Element {
  const isMounted = useIsMounted()

  return (
    <Modal modalCssOverride={modalCss} isOpen={isOpen} title="Account Sign In" onClose={onClose}>
      <section css={contentCss}>
        {isMounted && (
          <>
            {/* these are regular anchor elements so Gatsby doesn't try to prefetch */}
            <a data-testid="sign-in-button" css={[buttonLinkCss, primaryCss]} href={getLoginUrl()}>
              Sign in
            </a>
            <p css={dividerCss}>or</p>
            <a css={buttonLinkCss} href={`${getLoginUrl()}&idp=${MARKETPLACE_IDP}`}>
              Sign in with S&P Marketplace
            </a>
          </>
        )}
      </section>
    </Modal>
  )
}
