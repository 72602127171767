import React, {useMemo} from 'react'
import {PageProps} from 'gatsby'
import {css} from '@emotion/react'

import {NavEntry} from './types'
import NavItemWithPopup from './NavItemWithPopup'

const LINK_ACTIVE_CLASS_NAME = 'active'
const LINK_PADDING = 8

const navCss = css`
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 10px;
`

const denseNavCss = css`
  margin-right: 0;
`

const navListCss = css`
  display: flex;

  align-items: center;
  list-style-type: none;
  margin: 0;
  height: 100%;

  div > li,
  div {
    height: 100%;
  }
`
const subNavListCss = css`
  list-style-type: none;
  min-width: 130px;
  margin: 0;
  display: block;
`
const subNavListPopoverCss = css`
  background-color: #353b3e;
  max-width: 200px;
  border-radius: 0;
`

const menuButtonCss = css`
  width: 100%;
  flex: 1 1 0%;
  color: white;
  text-decoration: none;
  padding: 0 8px;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;

  &:hover {
    background: rgb(95, 101, 106);
  }
`

const linkCss = css`
  display: flex;
  align-items: center;
  position: relative;
  color: rgba(255, 255, 255, 0.72);
  outline: none;
  text-decoration: none;
  margin-right: 20px;
  font-size: 16px;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.1s linear;
  padding: 0 ${LINK_PADDING}px;

  &:after {
    content: '';
    position: absolute;
    left: ${LINK_PADDING}px;
    bottom: 0;
    height: 3px;
    width: calc(100% - ${LINK_PADDING * 2}px);
  }

  &.${LINK_ACTIVE_CLASS_NAME}, &.${LINK_ACTIVE_CLASS_NAME}:hover {
    font-weight: bold;
    color: #90dbec;
    &:after {
      background-color: #90dbec;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    &:after {
      background-color: rgba(255, 255, 255, 0.54);
    }
  }
`

const denseLinkCss = css`
  font-size: 14px;
  margin: 0 6px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.87);
  padding: 0 6px;
`

const encodeSimpleURIComponent: (str?: string) => string = (str = '') =>
  str
    .trim()
    .replace(/[^a-z0-9-\s]/gi, '')
    .replace(/\s+/g, '-')
    .toLowerCase()

interface HorizontalNavProps {
  navList?: NavEntry[]
  location: PageProps['location']
  pathPrefix?: string
  rollupSections?: boolean
  activeItem?: string
  activeSection?: string
  dense?: boolean
}

export default function HorizontalNav(props: HorizontalNavProps): JSX.Element {
  const {
    navList = [],
    location,
    pathPrefix = '',
    rollupSections = true,
    activeItem,
    activeSection,
    dense,
  } = props

  const derivedNavList = useMemo(() => {
    if (!rollupSections) return navList
    const sections = new Set<string>()
    return navList.reduce<NavEntry[]>((acc, navEntry) => {
      if (!navEntry.section) {
        acc.push(navEntry)
      } else if (navEntry.type === 'section-overview') {
        if (sections.has(navEntry.section)) {
          const sectionIndex = acc.findIndex(
            (navListEntry) => navListEntry.section === navEntry.section
          )
          const existingItems = acc[sectionIndex].items
          acc[sectionIndex] = navEntry
          if (existingItems) {
            acc[sectionIndex].items = existingItems
          }
        } else {
          sections.add(navEntry.section)
          acc.push(navEntry)
        }
      } else if (!sections.has(navEntry.section)) {
        sections.add(navEntry.section)
        acc.push({
          title: navEntry.section,
          url: `/${encodeSimpleURIComponent(navEntry.section)}`,
          section: navEntry.section,
        })
      }

      // pushing subsection pages into menu
      if (navEntry.section && navEntry.type !== 'section-overview') {
        const sectionIndex = acc.findIndex(
          (navListEntry) => navListEntry.section === navEntry.section
        )
        if (!acc[sectionIndex].items) {
          acc[sectionIndex].items = []
        }
        if (!acc[sectionIndex].items?.includes(navEntry)) {
          acc[sectionIndex].items?.push(navEntry)
        }
      }

      return acc
    }, [])
  }, [rollupSections, navList])

  return (
    <nav css={[navCss, dense && denseNavCss]}>
      <ul css={navListCss}>
        {!!derivedNavList?.length &&
          derivedNavList.map((entry) => (
            <NavItemWithPopup
              flat
              location={location}
              key={entry.title}
              pathPrefix={pathPrefix}
              linkClassName={
                (activeItem && activeItem === entry.url) ||
                (activeSection && entry.section === activeSection)
                  ? 'active'
                  : ''
              }
              linkCss={[linkCss, dense && denseLinkCss]}
              popoverCss={subNavListPopoverCss}
              listCss={subNavListCss}
              listItemCss={menuButtonCss}
              navEntry={entry}
            />
          ))}
      </ul>
    </nav>
  )
}
