import React, {useRef, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {css, SerializedStyles, Theme, Interpolation} from '@emotion/react'
import {Manager, Reference, Popper, PopperProps} from 'react-popper'

import PopoverDynamicContent from './PopoverDynamicContent'

interface PopoverProps {
  isOpen: boolean
  target: React.ReactNode
  children: React.ReactNode
  popperModifiers?: PopperProps['modifiers']
  popperPlacement?: PopperProps['placement']
  popoverClassName?: string
  popoverCss?: Interpolation<Theme>
}

const popoverSurfaceCss = css`
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 5px;
  max-height: 100%;
  z-index: 600;
`

const removePortalNode = (node: Node): void => {
  try {
    if (node) document.body.removeChild(node)
  } catch (e) {
    // ignore
  }
}

export default function Popover(props: PopoverProps): JSX.Element {
  const {
    isOpen,
    children,
    popperPlacement = 'bottom-end',
    popperModifiers,
    target,
    popoverClassName,
    popoverCss,
  } = props

  const portalContainerRef = useRef<HTMLDivElement | null>(null)
  const [portalMounted, setPortalMounted] = useState(!!portalContainerRef.current)

  useEffect(() => {
    if (isOpen) {
      portalContainerRef.current = document.createElement('div')
      portalContainerRef.current.className = 'popover-container'
      document.body.appendChild(portalContainerRef.current)
      setPortalMounted(true)
    }
    return () => {
      if (portalContainerRef.current) {
        removePortalNode(portalContainerRef.current)
        portalContainerRef.current = null
        setPortalMounted(false)
      }
    }
  }, [isOpen])

  // remove portal container node on unmount
  useEffect(
    () => () => {
      if (portalContainerRef.current) removePortalNode(portalContainerRef.current)
    },
    []
  )

  return (
    <Manager>
      <Reference>{({ref}) => <div ref={ref}>{target}</div>}</Reference>
      {isOpen && portalMounted && (
        <Popper placement={popperPlacement} modifiers={popperModifiers}>
          {({ref, style, placement, update}) =>
            createPortal(
              <div
                ref={ref}
                data-placement={placement}
                style={style}
                className={popoverClassName}
                css={[popoverSurfaceCss, popoverCss]}
              >
                <PopoverDynamicContent onResize={update}>{children}</PopoverDynamicContent>
              </div>,
              portalContainerRef.current
            )
          }
        </Popper>
      )}
    </Manager>
  )
}
