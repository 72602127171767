import {css} from '@emotion/react'
import {IconTriangleDown, IconTriangleUp} from '@kensho/icons'
import React, {useContext, useState} from 'react'

import VersionContext from '../providers/VersionContext'

import Link from './Link'
import Popover from './Popover'

const buttonResetCss = css`
  outline: none;
  appearance: none;
  border: none;
  background: none;
`

const popoverCss = css`
  overflow-y: hidden;
  max-width: 175px;
  padding: 0 5px;
  transition: max-height 0.25s, padding 0.25s;
  background-color: #353b3e;
  max-width: 200px;
  border-radius: 0;
`

const versionSelectCss = css`
  ${buttonResetCss}
  display: inline-flex;
  justify-content: space-between;
  background: none;
  border-width: 0.5px;
  border-radius: 4px;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
  transition: all 0.1s;
  min-width: 50px;
  margin: 0 12px;
  position: relative;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    border-color: #2d2d2d;
    background: rgb(95, 101, 106);
  }
`

const dropdownCaretCss = css`
  width: 20px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`

const versionsPopoverCss = css`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  min-width: 125px;
  background-color: #353b3e;
  max-width: 200px;
  border-radius: 0;
  margin: 0;
  padding: 2px 0;
`

const versionLinkCss = css`
  width: 100%;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 5px;
  margin: 2px 0;
  padding: 0 5px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
  }
`

export default function VersionSelector(): JSX.Element | null {
  const {versions, version} = useContext(VersionContext)
  const [isOpen, setIsOpen] = useState(false)

  const publicVersions = versions.filter((v) => v.public !== false)

  if (!version) return null

  return (
    <Popover
      isOpen={isOpen}
      popperPlacement="bottom-start"
      target={
        <button
          type="button"
          css={versionSelectCss}
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        >
          {version.latest || publicVersions.length < 2
            ? `${version.identifier} (Latest)`
            : version.identifier}
          {publicVersions.length > 1 && (
            <div css={dropdownCaretCss}>
              {isOpen ? <IconTriangleUp color="#ffffff" /> : <IconTriangleDown color="#ffffff" />}
            </div>
          )}
        </button>
      }
      popoverCss={popoverCss}
    >
      <ul css={versionsPopoverCss}>
        {publicVersions.map((v) => (
          <li key={v.identifier}>
            <Link css={versionLinkCss} to={`/${v?.latest ? '' : `${v?.identifier}/`}`}>
              {v.latest ? `${v.identifier} (Latest)` : v.identifier}
            </Link>
          </li>
        ))}
      </ul>
    </Popover>
  )
}
