import React from 'react'
import {css} from '@emotion/react'

const kenshoLogoCss = css`
  width: 19px;
  height: 19px;
`

interface KenshoLogoProps {
  className?: string
}

/** Kensho logo in blue */
export default function KenshoLogo(props: KenshoLogoProps): JSX.Element {
  const {className} = props
  return (
    <svg
      className={className}
      css={kenshoLogoCss}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6868 9.24934C18.6886 10.7152 18.3361 12.1602 17.6589 13.4637C17.6349 13.5104 17.6088 13.5578 17.5863 13.6074L16.917 13.2618C16.9424 13.2151 16.9685 13.1669 16.9932 13.1181C17.7478 11.6556 18.058 10.008 17.8864 8.37468C17.7148 6.74133 17.0687 5.1922 16.0263 3.91473L14.9032 4.82731C14.8699 4.78635 14.8357 4.74395 14.8016 4.70443C14.1895 3.98365 13.4377 3.39149 12.5906 2.96288C11.7436 2.53428 10.8184 2.2779 9.86969 2.20886L9.75644 4.02036C9.70344 4.01533 9.64755 4.01246 9.59456 4.0103C9.51035 4.00599 9.4276 4.00383 9.34339 4.00383C8.43854 4.00405 7.54881 4.2336 6.75898 4.67062C5.96915 5.10765 5.30553 5.73758 4.83135 6.50042C4.35716 7.26325 4.0882 8.13356 4.05009 9.02844C4.01198 9.92332 4.20601 10.8129 4.61367 11.6126C5.02132 12.4122 5.62903 13.0952 6.37891 13.5964C7.12878 14.0977 7.99584 14.4006 8.89746 14.4761C9.79908 14.5517 10.7052 14.3975 11.5296 14.0282C12.3539 13.6589 13.069 13.0868 13.6067 12.3665C13.6394 12.3234 13.6706 12.2802 13.7011 12.2364L15.1907 13.2927L16.374 14.1305L16.9874 14.5617C16.9569 14.6048 16.9242 14.6501 16.893 14.6917C16.0271 15.8708 14.8917 16.8304 13.5797 17.4923C12.2676 18.1541 10.816 18.4995 9.34339 18.5001C4.18932 18.5001 0 14.3497 0 9.25006C0 4.15042 4.19004 0 9.33976 0C9.50817 0 9.67659 0.00503 9.84283 0.0143713C9.89654 0.0143713 9.95026 0.0194013 10.0047 0.0237126C11.2558 0.112486 12.476 0.449839 13.5925 1.01564C14.709 1.58144 15.699 2.36409 16.5032 3.31689C16.5388 3.35928 16.5758 3.3988 16.6048 3.43976C17.9522 5.08214 18.6874 7.13361 18.6868 9.24934Z"
        fill="#50AFC6"
      />
    </svg>
  )
}
