import React from 'react'
import Helmet from 'react-helmet'
import {css} from '@emotion/react'

import '../fonts/ProximaNova.css'
// keep default gatsby global styles for now, since some plugins expect them
import './Base.css'
import Footer from '../components/Footer'
import ErrorModal from '../components/ErrorModal'
import siteConfig from '../docs/siteconfig.yaml'
import {SiteConfig} from '../types'
import CookieConsentPopup from '../components/CookieConsentPopup'

const {envVars} = siteConfig as SiteConfig

const pageWrapperCss = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

interface BaseProps {
  children?: React.ReactNode
}

export default function Base({children}: BaseProps): JSX.Element {
  return (
    <div css={pageWrapperCss}>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/mle2weo.css" />
        {/* <!-- Google tag (gtag.js) --> */}
        {!!envVars?.ga4ID && (
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${envVars.ga4ID}`} />
        )}
        {!!envVars?.ga4ID && (
          <script>
            {`
          window['ga-disable-${envVars.ga4ID}'] = document.cookie.indexOf('gdprConsentCookie=true') === -1;
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${envVars.ga4ID}');
          `}
          </script>
        )}
      </Helmet>
      {children}
      <Footer />
      <CookieConsentPopup />
      <ErrorModal />
    </div>
  )
}
