import {css} from '@emotion/react'
import React, {useEffect, useRef, useState} from 'react'

import Link from '../Link'
import Popover from '../Popover'

import getNavItemUrl from './getNavItemUrl'
import {NavEntry, NavItemWithPopupProps} from './types'

const hiddenPopoverCss = css`
  display: none;
`

const defaultPopoverCss = css`
  overflow-y: auto;
  max-height: 0px;
  max-width: 175px;
  padding: 0 5px;
  transition: max-height 0.25s, padding 0.25s;
`

const onHoverCss = css`
  padding: 5px;
  max-height: 600px;

  @media (max-height: 600px) {
    max-height: 95vh;
  }
`

export default function NavItemWithPopup({
  navEntry,
  pathPrefix = '',
  className,
  linkCss,
  listCss,
  listItemCss,
  popoverCss,
  linkClassName = '',
}: NavItemWithPopupProps): JSX.Element {
  const {title, url = '', items} = navEntry

  const [isHovered, setIsHovered] = useState(false)
  const deferTimerRef = useRef<number | null>(null)
  useEffect(
    () => () => {
      if (deferTimerRef.current) clearTimeout(deferTimerRef.current)
    },
    []
  )
  const toggledIsHovered = (): void => {
    setIsHovered((prevIsHovered) => !prevIsHovered)
  }
  const setHoveredOnMouseEnter = (): void => {
    setIsHovered(true)
    if (deferTimerRef.current) clearTimeout(deferTimerRef.current)
  }
  const setHoveredOnMouseLeave = (): void => {
    deferTimerRef.current = window.setTimeout(() => {
      setIsHovered(false)
    }, 100)
  }

  const createLink = (entry: NavEntry): JSX.Element | string =>
    entry.url ? (
      <Link css={listItemCss} to={getNavItemUrl(entry, pathPrefix)}>
        {entry.title}
      </Link>
    ) : (
      entry.title
    )

  return (
    <Popover
      popperPlacement="bottom-start"
      isOpen
      target={
        <li
          className={className}
          onMouseEnter={() => setHoveredOnMouseEnter()}
          onMouseLeave={() => setHoveredOnMouseLeave()}
        >
          {url ? (
            <Link
              css={linkCss}
              className={linkClassName}
              to={getNavItemUrl(navEntry, pathPrefix)}
              onClick={() => toggledIsHovered()}
            >
              {title}
            </Link>
          ) : (
            title
          )}
        </li>
      }
      popoverCss={[
        defaultPopoverCss,
        popoverCss,
        isHovered && onHoverCss,
        !items?.length && hiddenPopoverCss,
      ]}
    >
      <ul
        css={listCss}
        onMouseEnter={() => setHoveredOnMouseEnter()}
        onMouseLeave={() => setHoveredOnMouseLeave()}
      >
        {items?.map((entry) => (
          <li key={`${entry.title}:${entry.url}`}>{createLink(entry)}</li>
        ))}
      </ul>
    </Popover>
  )
}
